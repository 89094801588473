import {MenuItem} from './_core/_layout/settings/menu-settings.config';


export const LoggedInMenuItems: Partial<MenuItem>[] = [
    {
        title: 'Easy Upload',
        icon: 'la-cloud-upload',
        page: '/easy-upload',
        // badge: {type: 'badge-danger', value: '3.0'}
    },
    {
        title: 'Recent Photos',
        icon: 'la-file-photo-o',
        page: '/recent-photos',
        // badge: {type: 'badge-danger', value: '3.0'}
    },
    {
        title: 'Gallery',
        icon: 'la-picture-o',
        page: '/gallery',
        // badge: {type: 'badge-danger', value: '3.0'}
    },

    {
        title: 'Manage Relations',
        icon: 'la-group',
        page: '/relations',
        // badge: {type: 'badge-danger', value: '3.0'}
    },
    {
        title: 'Change Log',
        icon: 'la-file',
        page: '/changelog',
    }

    // {
    //     title: 'profile-settings',
    //     icon: 'la-file',
    //     page: '/profile-settings',
    //     badge: {type: 'badge-danger', value: '3.0'}
    // },


];

// {
//   title: 'Changelog',
//   icon: 'la-file',
//   page: '/changelog',
//   badge: { type: 'badge-danger', value: '3.0' }
// },
// {
//   title: 'Templates',
//   icon: 'la-television',
//   page: 'null',
//   submenu: {
//     items: [
//       {
//         title: 'Horizontal',
//         page: 'null'
//       },
//       {
//         title: 'Vertical',
//         page: 'null'
//       },
//     ]
//   }
// },
// {
//   title: 'Raise Support',
//   icon: 'la-support',
//   page: 'https://pixinvent.ticksy.com/',
//   isExternalLink: true
// },
// {
//   title: 'Documentaion',
//   icon: 'la-text-height',
//   page: 'https://modern-admin-docs.web.app/html/ltr/documentation/index.html',
//   isExternalLink: true,
// }
