// Default theme settings configurations
import {SiteSettings} from 'src/app/constants';

export const ThemeSettingsConfig = {
    colorTheme: 'semi-dark', // light, semi-light, semi-dark, dark
    layout: {
        style: 'vertical', // style: 'vertical', horizontal,
        pattern: 'fixed' // fixed, boxed, static
    },
    menuColor: 'navbar-light', // Vertical: [menu-dark, menu-light] , Horizontal: [navbar-dark, navbar-light]
    navigation: 'menu-collapsible', // menu-collapsible, menu-accordation
    menu: 'expand', // collapse, expand
    header: 'fix', // fix, static
    footer: 'static', // fix, static
    customizer: 'off', // on ,off
    headerIcons: {
        maximize: 'on', // on, off
        search: 'on', // on, off
        internationalization: 'off', // on, off
        notification: 'on', // on, off
        email: 'off' // on, off
    },
    brand: {
        brand_name: SiteSettings.title,
        logo: {
            type: 'internal', // internal, url
            value: 'assets/images/favicon_io/favicon-32x32.png' // recommended location for custom images
            // type:'url',
            // value:'http://evolvision.com/wp-content/uploads/2018/01/envelope4-green.png'
        },
    },
    defaultTitleSuffix: SiteSettings.title + ' |' + SiteSettings.title_short,
};
