import {NgModule} from '@angular/core';
import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig} from '@angular/platform-browser';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgbModule, NgbCarouselConfig, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {environment} from '../environments/environment';
import {AuthGuard} from './_core/_guards/auth.guard';
import {AlertComponent} from './_core/_directives/alert.component';
import {AlertService} from './_core/_services/alert.service';
import {AuthService} from './_core/_services/auth.service';

// Routing
import {routing} from './app.routing';

// Components
import {AppComponent} from './core-app/app.component';

import {SettingsModule} from './_core/_layout/settings/settings.module';
import {ThemeSettingsConfig} from './_core/_layout/settings/theme-settings.config';
import {MenuSettingsConfig} from './_core/_layout/settings/menu-settings.config';

import {HeaderComponent} from './_core/_layout/header/header.component';
import {VerticalComponent as HeaderVerticalComponent} from './_core/_layout/header/vertical/vertical.component';
import {HorizontalComponent as HeaderHorizontalComponent} from './_core/_layout/header/horizontal/horizontal.component';
import {FullLayoutNavbarComponent} from './_core/_layout/header/full-layout-navbar/full-layout-navbar.component';

import {FooterComponent} from './_core/_layout/footer/footer.component';
import {NavigationComponent as AppNavigationComponent} from './_core/_layout/navigation/navigation.component';

import {PublicLayoutComponent} from './_core/_layout/public-layout/public-layout.component';
import {PrivateLayoutComponent} from './_core/_layout/private-layout/private-layout.component';

import {RegisterComponent} from './pages/public/register';
import {SocialSigninComponent} from './pages/public/social-signin/social-signin.component';
import {LoginComponent} from './pages/public/login';

import {ChangelogComponent} from './pages/private/changelog/changelog.component';

import {NavbarService} from './_core/_services/navbar.service';
import {VerticalnavComponent} from './_core/_layout/navigation/verticalnav/verticalnav.component';
import {HorizontalnavComponent} from './_core/_layout/navigation/horizontalnav/horizontalnav.component';

// perfect scroll bar
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
// spinner
import {NgxSpinnerModule} from 'ngx-spinner';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {DeviceDetectorService} from './_core/_services/device-detector.service';
import {RouterModule} from '@angular/router';
import {CustomizerComponent} from './_core/_layout/customizer/customizer.component';
import {PartialsModule} from './pages/content/partials/partials.module';
import {BreadcrumbModule} from './_core/_layout/breadcrumb/breadcrumb.module';
import {DataApiService} from './_core/_services/data.api';
import {HorizontalCustomizerComponent} from './_core/_layout/customizer/horizontal-customizer/horizontal-customizer.component';
import {BlockTemplateComponent} from './_core/_layout/blockui/block-template.component';
import {BlockUIModule} from 'ng-block-ui';
import {MatchHeightModule} from './pages/content/partials/general/match-height/match-height.module';
import {FullLayoutComponent} from './_core/_layout/full-layout/full-layout.component';
import {ForgotPasswordComponent} from './pages/public/forgot-password/forgot-password.component';
import {MaterialModule} from './material.module';
import {ResetPasswordComponent} from './pages/public/reset-password/reset-password.component';

;
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

// import {}

@NgModule({
    imports: [
        BrowserModule,
        PartialsModule,
        MaterialModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatchHeightModule,
        BreadcrumbModule,
        NgbModule,
        FormsModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule, // imports firebase/firestore, only needed for database features
        AngularFireAuthModule, // imports firebase/auth, only needed for auth features
        routing,
        // Settings modules
        SettingsModule.forRoot(ThemeSettingsConfig, MenuSettingsConfig),
        PerfectScrollbarModule,
        NgxSpinnerModule,
        LoadingBarRouterModule,
        BlockUIModule.forRoot({
            template: BlockTemplateComponent
        })
        ,
        FontAwesomeModule
    ],
    declarations: [
        AppComponent,
        PublicLayoutComponent,
        PrivateLayoutComponent,
        HeaderComponent,
        FullLayoutNavbarComponent,
        HeaderHorizontalComponent,
        HeaderVerticalComponent,
        FooterComponent,
        AppNavigationComponent,
        AlertComponent,
        RegisterComponent,
        SocialSigninComponent,
        LoginComponent,
        ChangelogComponent,
        VerticalnavComponent,
        HorizontalnavComponent,
        CustomizerComponent,
        HorizontalCustomizerComponent,
        BlockTemplateComponent,
        FullLayoutComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,


    ],
    providers: [
        AuthGuard,
        AlertService,
        NavbarService,
        DeviceDetectorService,
        DataApiService,
        AuthService,
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerGestureConfig
        },
        NgbCarouselConfig,
        NgbModalConfig,
    ],
    entryComponents: [
        BlockTemplateComponent
    ],
    bootstrap: [AppComponent],
    exports: [RouterModule]
})

export class AppModule {

}
