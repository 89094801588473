// Default menu settings configurations

export interface MenuItem {
  title: string;
  icon: string;
  page: string;
  isExternalLink?: boolean;
  issupportExternalLink?: boolean;
  badge: { type: string, value: string };
  submenu: {
    items: Partial<MenuItem>[];
  };
  section: string;
}
export interface MenuConfig {
  horizontal_menu: {
    items: Partial<MenuItem>[]
  };
  vertical_menu: {
    items: Partial<MenuItem>[]
  };
}

import {LoggedInMenuItems} from '../../../logged-in-menu-items';
export const MenuSettingsConfig: MenuConfig = {
  horizontal_menu: {
      items: LoggedInMenuItems
  },
  vertical_menu: {
      items: LoggedInMenuItems
  }

};





