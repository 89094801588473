<div class="app-content content">
    <div class="content-wrapper">

        <div class="content-body">

            <section class="flexbox-container">
                <div class="row">
                    <div class="col-8">

                    </div>
                    <div class="col-4 d-flex align-items-center ">
                        <div class="col-md-12 col-12 box-shadow-2 p-0">
                            <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                                <div class="card-header border-0">
                                    <div class="card-title text-center">
                                        <img [src]="site_settings.logo_path"
                                             alt="branding logo" width="100px">
                                    </div>

                                </div>
                                <div class="card-content">
                                    <p class="card-subtitle line-on-side text-muted text-center font-small-3 mx-2 my-1">
                                        <span>Make Sharing Images Easy and Automatic</span></p>
                                    <div class="card-body">
                                        <form (ngSubmit)="tryLogin()" [formGroup]="loginForm" class="form-horizontal">
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                                       class="form-control" formControlName="email"
                                                       id="user-name"
                                                       placeholder="Your Username" required type="text">
                                                <div class="form-control-position">
                                                    <i class="feather ft-user"></i>
                                                </div>
                                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                    <div *ngIf="f.email.errors.required">Username is required</div>
                                                </div>
                                            </fieldset>
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                                                       class="form-control"
                                                       formControlName="password" placeholder="Enter Password"
                                                       type="password"/>
                                                <div class="form-control-position">
                                                    <i class="la la-key"></i>
                                                </div>
                                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                    <div *ngIf="f.password.errors.required">Password is required</div>
                                                </div>

                                            </fieldset>
                                            <div class="form-group row">
                                                <div class="col-md-6 col-12 text-center text-sm-left">
                                                    <div class="custom-control custom-checkbox mb-1">
                                                        <input class="custom-control-input" id="remember-me"
                                                               name="remember-me" type="checkbox">
                                                        <label class="custom-control-label" for="remember-me">Remember
                                                            Me</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-12 float-sm-left text-center text-sm-right"><a
                                                        [routerLink]="['/forgot-password']" class="card-link">Forgot
                                                    Password?</a>
                                                </div>
                                            </div>
                                            <button class="btn btn-outline-info btn-block" type="submit">
                                                <i *ngIf="submitted" class="fa fa-refresh fa-spin"></i>
                                                <i *ngIf="!submitted" class="feather ft-unlock"></i> Login
                                            </button>
                                        </form>
                                    </div>

                                    <div class="card-body">
                                        <a [routerLink]="['/register']" class="btn btn-outline-danger btn-block"><i
                                                class="feather ft-user"></i> Register</a>
                                    </div>
                                    <p class="card-subtitle line-on-side text-muted text-center font-small-3 mx-2 my-1">
                                        <span>Sign in using another sign-in</span></p>
                                    <app-social-signin></app-social-signin>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </div>
</div>
