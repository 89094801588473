import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {AuthService} from '../../../_core/_services/auth.service';
import {AlertService} from '../../../_core/_services/alert.service';
import {SiteSettings} from '../../../constants';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {ProfilePrivateInterface} from '../../../interfaces/profile-firebase-interface';
import firebase from 'firebase/compat/app';
import {UserService} from '../../../services/user/user.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Timestamp} from 'firebase/firestore';

@Component({
    templateUrl: 'register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
    registerForm: FormGroup;
    loading = false;
    submitted = false;
    errorMessage = '';
    successMessage = '';
    site_settings = SiteSettings;


    userID: string | null = null;
    shareID: string | null = null;
    shareType: string | null = null;
    easyConnect = false;
    referenceUser: ProfilePrivateInterface | null = null;

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private alertService: AlertService,
        private authService: AuthService,
        private userService: UserService,
        private route: ActivatedRoute,
        private db: AngularFirestore,
        private _snackbar: MatSnackBar,
        private firestore: AngularFirestore) {

    }

    lazy_snackbar(message: string) {
        return this._snackbar.open(message, 'Close', {
            duration: 3000
        });
    }

    // 'register-easy-connect/:userID/:shareID/:shareType'
    ngOnInit() {
        this.userID = this.route.snapshot.paramMap.get('userID');
        this.shareID = this.route.snapshot.paramMap.get('shareID');
        this.shareType = this.route.snapshot.paramMap.get('shareType');

        this.registerForm = this.formBuilder.group({
            firstName: ['', Validators.required],
            email: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });
        if (this.userID && this.shareID && this.shareType) {

            this.db.doc<ProfilePrivateInterface>(`profiles-private/${this.userID}`).get().subscribe((doc) => {
                if (doc.exists) {
                    const data = doc.data();
                    if (data) {
                        console.log(this.shareType);
                        console.log(data.easyConnections[this.shareType]);
                        // tslint:disable-next-line:max-line-length
                        if (data.easyConnections && data.easyConnections[this.shareType] && data.easyConnections[this.shareType].key === this.shareID) {
                            console.log('Easy Connect');
                            this.easyConnect = true;
                            this.referenceUser = data;
                        }
                        //     // this.registerForm.controls.firstName.setValue(data.firstName);
                        //     // this.registerForm.controls.email.setValue(data.email);
                    }
                }

            });
        }

    }

    // convenience getter for easy access to form fields
    get f() {
        return this.registerForm.controls;
    }


    tryRegister() {
        this.submitted = true;
        if (this.registerForm.invalid) {
            return;
        }
        this.loading = true;

        this.authService.doRegister(this.registerForm.value)
            .then(res => {
                this.loading = false;
                console.log(res);
                this.errorMessage = '';

                if (this.easyConnect) {

                    setTimeout(() => {
                        this.autoConnection(res);
                    }, 2000);
                } else {
                    this.lazy_snackbar('Registration Successful(A)').afterDismissed().subscribe(() => {
                        this.router.navigate(['/login']);
                    });
                }
            }, err => {
                console.log(err);
                this.loading = false;
                this.lazy_snackbar(err.message);
            });
    }

    autoConnection(res: firebase.auth.UserCredential) {
        console.log('autoConnection');
        console.log(`${this.userID}, ${res.user.uid}, ${this.shareType}`);
        this.userService.connectAccounts(this.userID, res.user.uid, this.shareType).then(() => {
            console.log('updated');

            this.lazy_snackbar('Registration Successful(B)').afterDismissed().subscribe(() => {
                this.router.navigate(['/login']);
            });
        });

    }


}
