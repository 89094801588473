import {Routes, RouterModule} from '@angular/router';
import {PublicLayoutComponent} from './_core/_layout/public-layout/public-layout.component';
import {PrivateLayoutComponent} from './_core/_layout/private-layout/private-layout.component';
import {AuthGuard} from './_core/_guards/auth.guard';
import {RegisterComponent} from './pages/public/register';
import {LoginComponent} from './pages/public/login';
import {ChangelogComponent} from './pages/private/changelog';
import {ForgotPasswordComponent} from './pages/public/forgot-password/forgot-password.component';
import {ShareContentModule} from './pages/public/share-content/share-content.module';

const appRoutes: Routes = [
    // Public layout
    {
        path: '',
        component: PublicLayoutComponent,
        children: [
            {path: 'register', component: RegisterComponent},
            {path: 'register-easy-connect/:userID/:shareID/:shareType', component: RegisterComponent},

            {path: 'login', component: LoginComponent},
            {path: 'forgot-password', component: ForgotPasswordComponent},
            {path: '', component: LoginComponent}
        ]
    },
    {
        path: 'shared',
        loadChildren: () => import('./pages/public/share-content/share-content.module').then(m => m.ShareContentModule)
    },
    // Private layout
    {
        path: '',
        component: PrivateLayoutComponent,
        children: [
            {path: 'logout', component: LoginComponent, canActivate: [AuthGuard]},
            {path: 'changelog', component: ChangelogComponent, canActivate: [AuthGuard]},
            {
                path: 'recent-photos',
                loadChildren: () => import('./pages/private/recent-photos/recent-photos.module').then(m => m.RecentPhotosModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'first-time',
                loadChildren: () => import('./pages/private/first-time/first-time.module').then(m => m.FirstTimeModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'gallery',
                loadChildren: () => import('./pages/private/gallery/gallery.module').then(m => m.GalleryModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'relations',
                loadChildren: () => import('./pages/private/pg-relations-v2/pg-relations-v2.module').then(m => m.PgRelationsV2Module),
                canActivate: [AuthGuard]
            },
            {
                path: 'profile-settings',
                loadChildren: () => import('./pages/private/profile-settings/profile-settings.module').then(m => m.ProfileSettingsModule),
                canActivate: [AuthGuard]
            },
            {
                path: 'easy-upload',
                loadChildren: () => import('./pages/private/easy-upload/easy-upload.module').then(m => m.EasyUploadModule),
                canActivate: [AuthGuard]
            },

        ],
    },
    // otherwise redirect to home
    {path: '**', redirectTo: 'changelog'}
];

export const routing = RouterModule.forRoot(appRoutes, {scrollOffset: [0, 0], scrollPositionRestoration: 'top'});
