<div class="app-content content">
    <div class="content-wrapper">
        <div class="content-body">
            <section class="flexbox-container">
                <div class="row">
                    <div class="col-12 d-flex align-items-center justify-content-center">
                        <div class="col-md-6 col-10 box-shadow-2 p-0">
                            <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                                <div class="card-header border-0">
                                    <div class="card-title text-center">
                                        <img [src]="site_settings.logo_path"
                                             alt="branding logo" width="100px">
                                    </div>
                                </div>
                                <div class="card-content">
                                    <p class="card-subtitle line-on-side text-muted text-center font-small-3 mx-2 my-1">
                                        <span>Enter your email to reset your password</span></p>
                                    <div class="card-body">
                                        <form (ngSubmit)="submitReset()" [formGroup]="resetForm"
                                              class="form-horizontal">
                                            <fieldset class="form-group position-relative has-icon-left">
                                                <input [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                                       class="form-control" formControlName="email"
                                                       id="user-email"
                                                       placeholder="Your Email" required type="text">
                                                <div class="form-control-position">
                                                    <i class="feather ft-mail"></i>
                                                </div>
                                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                    <div *ngIf="f.email.errors.required">Email is required</div>
                                                </div>
                                            </fieldset>
                                            <button class="btn btn-outline-info btn-block" type="submit">
                                                <i *ngIf="submitted" class="fa fa-refresh fa-spin"></i>
                                                <i *ngIf="!submitted" class="feather ft-unlock"></i> Reset Password
                                            </button>
                                        </form>
                                        <button class="btn btn-outline-secondary btn-block mt-2" routerLink="/login">
                                            <i class="feather ft-arrow-left"></i> Go Back
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>
