// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// WARNING - Values under "firebase" and value of "googleApiKey" needs to be replaced from your own accounts
// If left as is, it firbase and google map related functionality will not work on LIVE instance.
export const environment = {
    production: false,
    firebase: {
        apiKey: 'AIzaSyD30MgwO_yI5XTpKN4M36Sg-4sfdKCJHhc',
        authDomain: 'ourphotoshare-db690.firebaseapp.com',
        databaseURL: 'https://ourphotoshare-db690-default-rtdb.firebaseio.com',
        projectId: 'ourphotoshare-db690',
        storageBucket: 'ourphotoshare-db690.appspot.com',
        messagingSenderId: '199146757337',
        appId: '1:199146757337:web:8512d9b880daa00a20b2c8',
        measurementId: 'G-BMSFP6VJS1'
    },
    googleApiKey: 'AIzaSyAIIYOxA7qeetFz6TuR1Qewc0Rrjhzx7ZU'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
