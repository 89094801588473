<div class="app-content content">
    <div class="content-wrapper">
        <div *ngIf="easyConnect" class="content-body" style="margin-bottom: 20px">
            <section class="flexbox-container">
                <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-md-4 col-10 box-shadow-2 p-0">
                        <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                            <div class="card-header border-0 pb-0">
                                <div class="card-title text-center">
                                    <img [src]="site_settings.logo_path" alt="branding logo" width="100px">
                                </div>
                                <h6 class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2"><span>Easy Connect</span>
                                </h6>
                            </div>
                            <div class="card-content">
                                This sign up page is set up to automatically connect to the person below. If you don't
                                want to connect, you can always click on the "Normal Registration" button below.
                                <!--                                Create a table with the displayname and shareType-->
                                <table class="table table-striped">
                                    <thead>
                                    <tr>
                                        <th>Display Name</th>
                                        <th>Share Type</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{{ referenceUser?.displayName }}</td>
                                        <td>{{ shareType }}</td>
                                    </tr>
                                    </tbody>
                                </table>

                            </div>

                            <div class="card-content">
                                <p class="card-subtitle line-on-side text-muted text-center font-small-3 mx-2 my-1">
                                    <span>Sign up Below</span></p>
                                <div class="card-body">
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
        <div class="content-body">
            <section class="flexbox-container">
                <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="col-md-4 col-10 box-shadow-2 p-0">
                        <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
                            <div class="card-header border-0 pb-0">
                                <div *ngIf="!easyConnect" class="card-title text-center">
                                    <img [src]="site_settings.logo_path" alt="branding logo" width="100px">
                                </div>
                                <h6 class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2"><span>Easily Using</span>
                                </h6>
                            </div>
                            <div class="card-content">
                                <app-social-signin></app-social-signin>
                                <p class="card-subtitle line-on-side text-muted text-center font-small-3 mx-2 my-1">
                                    <span>OR Using Email</span></p>
                                <div class="card-body">
                                    <form (ngSubmit)="tryRegister()" [formGroup]="registerForm" class="form-horizontal">
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
                                                   class="form-control" formControlName="firstName"
                                                   placeholder="User Name"
                                                   type="text">
                                            <div class="form-control-position">
                                                <i class="feather ft-user"></i>
                                            </div>
                                            <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                                <div *ngIf="f.firstName.errors.required">Username is required</div>
                                            </div>
                                        </fieldset>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                                   class="form-control" email formControlName="email"
                                                   placeholder="Your Email Address"
                                                   required type="email">
                                            <div class="form-control-position">
                                                <i class="feather ft-mail"></i>
                                            </div>
                                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">Email is required</div>
                                            </div>
                                        </fieldset>
                                        <fieldset class="form-group position-relative has-icon-left">
                                            <input [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                                                   class="form-control" formControlName="password"
                                                   id="user-password"
                                                   placeholder="Enter Password" required type="password">
                                            <div class="form-control-position">
                                                <i class="la la-key"></i>
                                            </div>
                                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                <div *ngIf="f.password.errors.required">Password is required</div>
                                                <div *ngIf="f.password.errors.minlength">Password must be at least 6
                                                    characters
                                                </div>
                                            </div>
                                        </fieldset>
                                        <div class="form-group row">
                                            <div class="col-md-6 col-12 text-center text-sm-left">
                                                <div class="custom-control custom-checkbox mb-1">
                                                    <input class="custom-control-input" id="remember-me"
                                                           name="remember-me" type="checkbox">
                                                    <label class="custom-control-label" for="remember-me">Remember
                                                        Me</label>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-12 float-sm-left text-center text-sm-right"><a
                                                    class="card-link" href="recover-password.html">Forgot Password?</a>
                                            </div>
                                        </div>
                                        <button class="btn btn-outline-info btn-block" type="submit">
                                            <i *ngIf="submitted" class="fa fa-refresh fa-spin"></i>
                                            <i *ngIf="!submitted" class="feather ft-user"></i> Register
                                        </button>
                                    </form>
                                </div>
                                <div class="card-body">
                                    <a [routerLink]="['/login']" class="btn btn-outline-danger btn-block"><i
                                            class="feather ft-unlock"></i> Login</a>

                                    <a *ngIf="easyConnect" [routerLink]="['/register']"
                                       class="btn btn-outline-danger btn-block"><i
                                            class="feather ft-unlock"></i> Normal Registration</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </div>
</div>
