import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../_core/_services/auth.service';
import {AlertService} from '../../../_core/_services/alert.service';
import {SiteSettings} from '../../../constants';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
    resetForm: FormGroup;
    loading = false;
    submitted = false;
    site_settings = SiteSettings;

    constructor(
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        public authService: AuthService,
        private _snackBar: MatSnackBar) {
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.resetForm.controls;
    }

    ngOnInit() {
        this.resetForm = this.formBuilder.group({
            email: ['', Validators.required]
        });
    }

    submitReset() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.resetForm.invalid) {
            return;
        }

        const value = {
            email: this.f.email.value
        };
        console.log(value);
        this.authService.afAuth.sendPasswordResetEmail(value.email).then(() => {
            this._snackBar.open('Reset password email sent', 'Close', {
                duration: 2000,
            });
        });

    }
}
