import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
// import { AlertService } from '../alert/alert.service';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {combineLatest, of} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';

import {
    ProfileInterface,
    ProfileMiniInterface,
    ProfilePrivateInterface,
    UserInterfaceNoEmail
} from '../../interfaces/profile-firebase-interface';
import {map} from 'rxjs/operators';
import {Timestamp} from 'firebase/firestore';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    // public currentUser: Observable<ProfileType | null>;
    // public currentUserSnapshot: ProfileType | null;

    constructor(
        private router: Router,
        // private alertService: AlertService,
        private afAuth: AngularFireAuth,
        private db: AngularFirestore
    ) {

        // @ts-ignore


        // this.setCurrentUserSnapshot();
    }

    getDummyMininProfile(): ProfileMiniInterface {
        return {
            uid: 'uid',
            photoURL: 'photoURL',
            // backgroundUrl: 'backgroundUrl',
            displayName: 'displayName',
            // biography: 'biography',
            state: 'Null',
            date: Timestamp.fromDate(new Date()),
            creationDate: Timestamp.fromDate(new Date())
        };

    }

    getMyProfileInfo() {
        return this.afAuth.authState.pipe(
            switchMap((user) => {
                if (user) {
                    return this.db.doc<ProfileInterface>(`profiles/${user.uid}`).valueChanges().pipe(
                        map((profile) => {
                            return {uid: user.uid, ...profile};
                        })
                    );
                } else {
                    return of<ProfileInterface | null>(null);
                }
            }));

    }

    getMyProfileMiniInfo() {
        return this.afAuth.authState.pipe(
            switchMap((user) => {
                if (user) {
                    return this.db.doc<ProfileMiniInterface>(`profiles-mini/${user.uid}`).valueChanges().pipe(
                        map((profile) => {
                            return {uid: user.uid, ...profile};
                        })
                    );
                } else {
                    return of<ProfileMiniInterface | null>(null);
                }
            }));

    }

    getMyProfileNoEmailMiniProfileInfo() {
        return this.afAuth.authState.pipe(
            switchMap((user) => {
                if (user) {
                    return this.db.doc<ProfileMiniInterface>(`profiles-mini/${user.uid}`).valueChanges().pipe(
                        map((profile) => {

                            return {
                                uid: user.uid,
                                displayName: profile.displayName,
                                photoURL: profile.photoURL
                            } as UserInterfaceNoEmail;

                        })
                    );
                } else {
                    return of<UserInterfaceNoEmail | null>(null);
                }
            }));

    }

    connectAccounts(user1_uid: string, user2_uid: string, shareType) {
        const user1$ = this.getProfilePrivateInfo(user1_uid);
        const user2$ = this.getProfilePrivateInfo(user2_uid);

        return combineLatest([user1$, user2$]).pipe(take(1)).toPromise().then(([user1, user2]) => {
            // user1 and user2 are the latest values from each observable
            // You can now use these values to connect the accounts
            user1.relationList[user2.uid] = {
                uid: user2.uid,
                photoURL: user2.photoURL,
                displayName: user2.displayName,
                status: 'request-approved',
                creationDate: Timestamp.fromDate(new Date()),
                type: shareType
            };
            user2.relationList[user1.uid] = {
                uid: user1.uid,
                photoURL: user1.photoURL,
                displayName: user1.displayName,
                status: 'request-approved',
                creationDate: Timestamp.fromDate(new Date()),
                type: shareType
            };

            const batch = this.db.firestore.batch();
            // tslint:disable-next-line:max-line-length
            batch.update(this.db.collection('profiles-private').doc(user1.uid).ref, {
                [`relationList`]: {...user1.relationList}
            });
            // tslint:disable-next-line:max-line-length
            batch.update(this.db.collection('profiles-private').doc(user2.uid).ref, {
                [`relationList`]: {...user2.relationList}
            });
            return batch.commit();
        });

    }

    getMyProfilePrivateInfo() {
        return this.afAuth.authState.pipe(
            switchMap((user) => {
                if (user) {
                    return this.db.doc<ProfilePrivateInterface>(`profiles-private/${user.uid}`).valueChanges().pipe(
                        map((profile) => {
                            return {uid: user.uid, ...profile};
                        })
                    );
                } else {
                    return of<ProfilePrivateInterface>(null);
                }
            }));
    }

    getProfilePrivateInfo(uid) {
        return this.db.doc<ProfilePrivateInterface>(`profiles-private/${uid}`).valueChanges().pipe(
            map((profile) => {
                return {uid: uid, ...profile};
            })
        );
    }

    batchUpdateProfileInfo(uid, data) {
        const batch = this.db.firestore.batch();
        batch.update(this.db.doc<ProfilePrivateInterface>(`profiles-private/${uid}`).ref, data);
        batch.update(this.db.doc<ProfileInterface>(`profiles/${uid}`).ref, data);
        batch.update(this.db.doc<ProfileMiniInterface>(`profiles-mini/${uid}`).ref, data);
        return batch.commit();
    }

    getMyAuthUser() {
        return this.afAuth.authState;
    }

    signOut() {
        this.afAuth.signOut().then(() => {
            this.router.navigate(['/']);
        });
    }
}
