<!-- fixed-top-->

<nav
        [ngClass]="selectedHeaderNavBarClass"
        class="top-header header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow navbar-static-top navbar-light navbar-brand-center"
        id="top-header">
    <div class="navbar-wrapper">
        <div class="navbar-header" id="navbar-header">
            <ul class="nav navbar-nav flex-row">
                <li class="nav-item mobile-menu d-md-none mr-auto"><a
                        (click)="toggleNavigation($event)" class="nav-link nav-menu-main menu-toggle hidden-xs11"><i
                        class="feather ft-menu font-large-1"></i></a></li>
                <li class="nav-item"><a [routerLink]="['/dashboard/sales']" class="navbar-brand"><img
                        alt="modern admin logo"
                        class="brand-logo"
                        [src]="site_settings.logo_path">
                    <h3 class="brand-text">{{ _themeSettingsConfig.brand.brand_name }}</h3>
                </a></li>

                <li class="nav-item d-md-none"><a (click)="toggleNavbar($event)" class="nav-link open-navbar-container"
                                                  data-target="#navbar-mobile" data-toggle="collapse"><i
                        class="la la-ellipsis-v"></i></a></li>
            </ul>
        </div>
        <div class="navbar-container content">
            <div class="collapse navbar-collapse show" id="navbar-mobile">
                <ul class="nav navbar-nav mr-auto float-left">
                    <li class="nav-item d-none d-md-block"><a (click)="toggleFixMenu($event)"
                                                              [routerLink]=""
                                                              class="nav-link nav-menu-main menu-toggle hidden-xs"><i
                            class="feather ft-menu"></i></a></li>
                    <li class="nav-item d-none d-md-block"><a (click)="toggleFullScreen()" *ngIf="maximize === 'on'"
                                                              [routerLink]="" class="nav-link nav-link-expand"><i
                            class="ficon feather ft-maximize"></i></a></li>
                    <li class="nav-item nav-search"><a (click)="clickSearch()" *ngIf="search === 'on'"
                                                       [routerLink]="" class="nav-link nav-link-search"><i
                            class="ficon feather ft-search"></i></a>
                        <div [ngClass]="{'open': isHeaderSearchOpen}" class="search-input">
                            <input class="input" placeholder="Explore Modern..." type="text">
                        </div>
                    </li>
                </ul>
                <ul class="nav navbar-nav float-right">
                    <li *ngIf="internationalization === 'on'" class="dropdown-language nav-item" ngbDropdown>
                        <a [routerLink]="" class="dropdown-toggle nav-link" id="dropdown-flag" ngbDropdownToggle>
                            <i class="flag-icon flag-icon-gb"></i><span class="selected-language"></span>
                        </a>
                        <div aria-labelledby="dropdownLangMenu" class="dropdown-menu" ngbDropdownMenu>
                            <a [routerLink]="" class="dropdown-item">
                                <i class="flag-icon flag-icon-gb"></i> English
                            </a>
                            <a [routerLink]="" class="dropdown-item">
                                <i class="flag-icon flag-icon-fr"></i> French
                            </a>
                            <a [routerLink]="" class="dropdown-item">
                                <i class="flag-icon flag-icon-cn"></i> Chinese
                            </a>
                            <a [routerLink]="" class="dropdown-item">
                                <i class="flag-icon flag-icon-de"></i> German
                            </a>
                        </div>
                    </li>
                    <li *ngIf="notification === 'on'" class="dropdown-notification nav-item dropdown" ngbDropdown>
                        <a class="nav-link nav-link-label" ngbDropdownToggle>
                            <i class="ficon feather ft-bell"></i>
                            <span class="badge badge-pill badge-danger badge-up badge-glow">5</span>
                        </a>
                        <ul class="dropdown-menu-media dropdown-menu-right" ngbDropdownMenu>
                            <li class="dropdown-menu-header">
                                <h6 class="dropdown-header m-0"><span class="grey darken-2">Notifications</span></h6>
                                <span
                                        class="notification-tag badge badge-default badge-danger float-right m-0">5 New</span>
                            </li>
                            <li [perfectScrollbar]="config"
                                class="scrollable-container media-list w-100 ps-container ps-theme-dark ps-active-y"
                                fxFlex="auto">
                                <a href="javascript:void(0)">
                                    <div class="media">
                                        <div class="media-left align-self-center"><i
                                                class="feather ft-plus-square icon-bg-circle bg-cyan"></i>
                                        </div>
                                        <div class="media-body">
                                            <h6 class="media-heading">You have new order!</h6>
                                            <p class="notification-text font-small-3 text-muted">Lorem ipsum dolor sit
                                                amet, consectetuer
                                                elit.</p><small>
                                            <time class="media-meta text-muted" datetime="2015-06-11T18:29:20+08:00">30
                                                minutes
                                                ago
                                            </time>
                                        </small>
                                        </div>
                                    </div>
                                </a>
                                <a href="javascript:void(0)">
                                    <div class="media">
                                        <div class="media-left align-self-center"><i
                                                class="feather ft-download-cloud icon-bg-circle bg-red bg-darken-1"></i>
                                        </div>
                                        <div class="media-body">
                                            <h6 class="media-heading red darken-1">99% Server load</h6>
                                            <p class="notification-text font-small-3 text-muted">Aliquam tincidunt
                                                mauris eu risus.</p><small>
                                            <time class="media-meta text-muted" datetime="2015-06-11T18:29:20+08:00">
                                                Five hour
                                                ago
                                            </time>
                                        </small>
                                        </div>
                                    </div>
                                </a>
                                <a href="javascript:void(0)">
                                    <div class="media">
                                        <div class="media-left align-self-center"><i
                                                class="feather ft-alert-triangle icon-bg-circle bg-yellow bg-darken-3"></i>
                                        </div>
                                        <div class="media-body">
                                            <h6 class="media-heading yellow darken-3">Warning notifixation</h6>
                                            <p class="notification-text font-small-3 text-muted">Vestibulum auctor
                                                dapibus neque.</p><small>
                                            <time class="media-meta text-muted" datetime="2015-06-11T18:29:20+08:00">
                                                Today
                                            </time>
                                        </small>
                                        </div>
                                    </div>
                                </a>
                                <a href="javascript:void(0)">
                                    <div class="media">
                                        <div class="media-left align-self-center"><i
                                                class="feather ft-check-circle icon-bg-circle bg-cyan"></i>
                                        </div>
                                        <div class="media-body">
                                            <h6 class="media-heading">Complete the task</h6><small>
                                            <time class="media-meta text-muted" datetime="2015-06-11T18:29:20+08:00">
                                                Last
                                                week
                                            </time>
                                        </small>
                                        </div>
                                    </div>
                                </a>
                                <a href="javascript:void(0)">
                                    <div class="media">
                                        <div class="media-left align-self-center"><i
                                                class="feather ft-file icon-bg-circle bg-teal"></i></div>
                                        <div class="media-body">
                                            <h6 class="media-heading">Generate monthly report</h6><small>
                                            <time class="media-meta text-muted" datetime="2015-06-11T18:29:20+08:00">
                                                Last
                                                month
                                            </time>
                                        </small>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li class="dropdown-menu-footer"><a class="dropdown-item text-muted text-center"
                                                                href="javascript:void(0)">Read all notifications</a>
                            </li>
                        </ul>
                    </li>
                    <li *ngIf="email === 'on'" class="dropdown-notification nav-item" ngbDropdown>
                        <a class="nav-link nav-link-label" ngbDropdownToggle><i class="ficon feather ft-mail"></i></a>
                        <ul class="dropdown-menu-media dropdown-menu-right" ngbDropdownMenu>
                            <li class="dropdown-menu-header">
                                <h6 class="dropdown-header m-0"><span class="grey darken-2">Messages</span></h6><span
                                    class="notification-tag badge badge-default badge-warning float-right m-0">4 New</span>
                            </li>
                            <li [perfectScrollbar]="config"
                                class="scrollable-container media-list w-100 ps-container ps-theme-dark ps-active-y"
                                fxFlex="auto">
                                <a href="javascript:void(0)">
                                    <div class="media">
                                        <div class="media-left"><span
                                                class="avatar avatar-sm avatar-online rounded-circle"><img
                                                alt="avatar"
                                                src="../../../../assets/images/portrait/small/avatar-s-19.png"><i></i></span>
                                        </div>
                                        <div class="media-body">
                                            <h6 class="media-heading">Margaret Govan</h6>
                                            <p class="notification-text font-small-3 text-muted">I like your portfolio,
                                                let's start.</p>
                                            <small>
                                                <time class="media-meta text-muted"
                                                      datetime="2015-06-11T18:29:20+08:00">Today
                                                </time>
                                            </small>
                                        </div>
                                    </div>
                                </a>
                                <a href="javascript:void(0)">
                                    <div class="media">
                                        <div class="media-left"><span
                                                class="avatar avatar-sm avatar-busy rounded-circle"><img
                                                alt="avatar"
                                                src="../../../../assets/images/portrait/small/avatar-s-2.png"><i></i></span>
                                        </div>
                                        <div class="media-body">
                                            <h6 class="media-heading">Bret Lezama</h6>
                                            <p class="notification-text font-small-3 text-muted">I have seen your work,
                                                there is</p><small>
                                            <time class="media-meta text-muted" datetime="2015-06-11T18:29:20+08:00">
                                                Tuesday
                                            </time>
                                        </small>
                                        </div>
                                    </div>
                                </a>
                                <a href="javascript:void(0)">
                                    <div class="media">
                                        <div class="media-left"><span
                                                class="avatar avatar-sm avatar-online rounded-circle"><img
                                                alt="avatar"
                                                src="../../../../assets/images/portrait/small/avatar-s-3.png"><i></i></span>
                                        </div>
                                        <div class="media-body">
                                            <h6 class="media-heading">Carie Berra</h6>
                                            <p class="notification-text font-small-3 text-muted">Can we have call in
                                                this week ?</p><small>
                                            <time class="media-meta text-muted" datetime="2015-06-11T18:29:20+08:00">
                                                Friday
                                            </time>
                                        </small>
                                        </div>
                                    </div>
                                </a>
                                <a href="javascript:void(0)">
                                    <div class="media border_bottom">
                                        <div class="media-left"><span
                                                class="avatar avatar-sm avatar-away rounded-circle"><img
                                                alt="avatar"
                                                src="../../../../assets/images/portrait/small/avatar-s-6.png"><i></i></span>
                                        </div>
                                        <div class="media-body">
                                            <h6 class="media-heading">Eric Alsobrook</h6>
                                            <p class="notification-text font-small-3 text-muted">We have project party
                                                this saturday.</p>
                                            <small>
                                                <time class="media-meta text-muted"
                                                      datetime="2015-06-11T18:29:20+08:00">last
                                                    month
                                                </time>
                                            </small>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li class="dropdown-menu-footer"><a class="dropdown-item text-muted text-center"
                                                                href="javascript:void(0)">Read all messages</a></li>
                        </ul>
                    </li>

                    <li class="dropdown-user nav-item" ngbDropdown>
                        <a class="nav-link dropdown-user-link" ngbDropdownToggle>
              <span *ngIf="currentUser.displayName"
                    class="mr-1 user-name text-bold-700">{{ currentUser.displayName }}</span>
                            <span *ngIf="!currentUser.displayName" class="mr-1 user-name text-bold-700">John Doe</span>
                            <span class="avatar avatar-online">
                <img *ngIf="currentUser.photoURL" src="{{currentUser.photoURL}}" alt="avatar">
                <img *ngIf="!currentUser.photoURL" src="../../../../assets/images/portrait/small/avatar-s-19.png"
                     alt="avatar">
                <i></i>
              </span>
                        </a>
                        <div aria-labelledby="dropdownProfileMenu" class="dropdown-menu dropdown-menu-right"
                             ngbDropdownMenu>
                            <a [routerLink]="['/user/user-profile']" class="dropdown-item"><i
                                    class="feather ft-user"></i> Edit Profile </a>
                            <a [routerLink]="['/email']" class="dropdown-item"><i class="feather ft-mail"></i> My Inbox
                            </a>
                            <a [routerLink]="['/todos']" class="dropdown-item"><i class="feather ft-check-square"></i>
                                Task </a>
                            <a [routerLink]="['/chats']" class="dropdown-item"><i class="feather ft-message-square"></i>
                                asd </a>
                            <div class="dropdown-divider"></div>
                            <a (click)="logout()" [routerLink]="" class="dropdown-item"><i class="feather ft-power"></i>
                                Logout</a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>
